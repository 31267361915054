/* eslint-disable */
import { uaParser } from '@/transformers/ua'
import Layout from '../layout'

const { isMobile } = uaParser()

const platformPath = isMobile ? 'mobile' : 'pc'


const gettersRouter = {
  Layout,
  Dashboard: () => import(/* webpackChunkName: "Dashboard" */ `../views/${platformPath}/Dashboard/index.vue`),
  Sidebar: () => import(/* webpackChunkName: "Sidebar" */ `../views/${platformPath}/Sidebar/index.vue`),
  About: () => import(/* webpackChunkName: "About" */ `../views/${platformPath}/About/index.vue`),
  Login: () => import(/* webpackChunkName: "Login" */ `../views/${platformPath}/Login/index.vue`),
  Admin: () => import(/* webpackChunkName: "Admin" */ `../views/${platformPath}/Sys/Admin/index.vue`),
  Rule: () => import(/* webpackChunkName: "Rule" */ `../views/${platformPath}/Sys/Rule/index.vue`),
  Group: () => import(/* webpackChunkName: "Group" */ `../views/${platformPath}/Sys/Group/index.vue`),
  OrderList: () => import(/* webpackChunkName: "OrderList" */ `../views/${platformPath}/Order/List/index.vue`),
  ConsultList: () => import(/* webpackChunkName: "ConsultList" */ `../views/${platformPath}/Consult/List/index.vue`),
  SetService: () => import(/* webpackChunkName: "SetService" */ `../views/${platformPath}/Consult/SetService/index.vue`),
  CasesList: () => import(/* webpackChunkName: "CasesList" */ `../views/${platformPath}/Cases/List/index.vue`),
  MobileList: () => import(/* webpackChunkName: "MobileList" */ `../views/${platformPath}/Service/MobileList/index.vue`),
}

export default gettersRouter
