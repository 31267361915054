<template>
<div>
  <template v-if="isMobile">
    <MobileMainView />
    <MobileAppMain />
  </template>
  <template v-else>
    <PcMainView />
    <PcAppMain />
  </template>
</div>
</template>

<script>
import { mapState } from 'vuex'
import PcMainView from '@/components/pc/layout/main'
import MobileMainView from '@/components/mobile/layout/main'
import PcAppMain from '@/layout/components/pc/AppMain'
import MobileAppMain from '@/layout/components/mobile/AppMain'

export default {
  name: 'LayoutView',
  computed: {
    ...mapState('global', ['isMobile'])
  },
  components: {
    PcMainView,
    MobileMainView,
    PcAppMain,
    MobileAppMain
  }
}
</script>

<style scoped>

</style>
