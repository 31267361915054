import { HttpPost } from '@/utils/request'

// 用户信息
export async function userSysInfoApi () {
  return await HttpPost('/out_put/userSysInfo')
}

// 服务项目
export async function serviceItemApi () {
  return await HttpPost('/out_put/serviceItem')
}

// 地区
export async function areaApi (jsonData) {
  return await HttpPost('/out_put/area', jsonData)
}

// 案件类型
export async function casesTypeApi (jsonData) {
  return await HttpPost('/out_put/casesType', jsonData)
}

// 服务手机号码
export async function serviceMobileApi () {
  return await HttpPost('/out_put/serviceMobile')
}
