const state = {
  hideAside: false
}
const mutations = {
  UPDATEHIDEASIDE (state, value) {
    state.hideAside = value
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
