<template>
  <div class="aside-container" :class="{'hide-aside': hideAside}">
    <div class="my-logo">
      <img src="~@/assets/images/logo.png" alt="">
      <span v-show="!hideAside">电律后台</span>
    </div>
    <el-menu
        :collapse="hideAside"
        :unique-opened="false"
        :collapse-transition="false"
        mode="vertical"
        :default-active="activeMenu()"
    >
      <sidebar-item v-for="route in permissionRoutes" :key="route.id" :item="route" :base-path="route.path" />
    </el-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarItem from '@/components/pc/layout/components/SidebarItem'

export default {
  name: 'PcNavView',
  props: {
  },
  components: {
    SidebarItem
  },
  computed: {
    ...mapState('permission', { permissionRoutes: state => state.routes }),
    ...mapState('sys', ['hideAside'])
  },
  mounted () {
  },
  methods: {
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/pc/variables.scss";

.aside-container {
  @include transition(width);
  width: $asideWidth;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow-y: hidden;
  border-right: 1px solid #eeeeee;

  &.hide-aside {
    width: $asideHideWidth;
  }

  .my-logo {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #ffffff;
    background: #2c3e50;
    //background: $subMenuHover;

    img {
      width: 40px;
      margin-right: 10px;
    }
  }

  .el-menu {
    height: calc(100% - 60px);
    overflow-y: scroll;
    border-right: none;
    @include transition(width);
   /* background: $menuBg;
    color: $menuText;*/

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      box-shadow: none;
      background: none;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 0;
      background: none;
    }

    /*.el-menu-item {
      color: $menuText;

      &:hover, &:focus {
        background: $menuHover;
      }

      i {
        color: $menuText;
      }
    }

    .el-submenu__title {
      color: $menuText;
    }

    .el-menu-item-group {
      background: $subMenuBg;
    }*/
  }

  /*.el-submenu__title {
    &:hover {
      background-color: $menuHover !important;
    }
  }

  .el-menu-item {
    &.is-active {
      color: $menuActiveText !important;
    }
  }

  .el-submenu .el-menu-item {
    background-color: $subMenuBg !important;

    &:hover {
      background-color: $subMenuHover !important;
    }
  }*/
}
</style>
