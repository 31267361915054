import { constantRoutes } from '@/router'
import gettersRouter from '@/router/getters'
import { Loading } from 'element-ui'

function hasPermission (indentify, route) {
  if (route && route.indentify) {
    return indentify.some(role => route.indentify.includes(role))
  } else {
    return true
  }
}

export function filterAsyncRoutes (routes, indentify) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    const componentName = tmp.component
    tmp.component = gettersRouter[componentName]
    if (hasPermission(indentify, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, indentify)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}
const actions = {
  generateRoutes ({ commit }, { indentify, routes }) {
    // 启动加载动画
    const loadingInstance = Loading.service({ fullscreen: true })

    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(routes, indentify)
      /* if (roles.includes('admin')) {
        accessedRoutes = routes
      } else {
        accessedRoutes = filterAsyncRoutes(routes, roles)
      } */
      // accessedRoutes = filterAsyncRoutes(routes, roles)
      commit('SET_ROUTES', accessedRoutes)
      // console.log(newAccessedRoutes)
      loadingInstance.close()
      // 添加404页面
      accessedRoutes.push({
        path: '*',
        redirect: '/404'
      })
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
