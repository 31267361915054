import { casesTypeApi, serviceItemApi } from '@/api/out_put'

const state = {
  payStatusSelect: [{
    label: '全部',
    value: ''
  }, {
    label: '未支付',
    value: 1
  }, {
    label: '已支付',
    value: 2
  }],
  orderSourceSelect: [{
    label: '全部',
    value: ''
  }, {
    label: '产品',
    value: 1
  }, {
    label: '追问',
    value: 2
  }],
  orderTypeSelect: [{
    label: '全部',
    value: ''
  }, {
    label: '用户',
    value: 1
  }, {
    label: '后台',
    value: 2
  }],
  callStatusSelect: [{
    label: '全部',
    value: ''
  }, {
    label: '未拨打电话',
    value: 1
  }, {
    label: '拨打电话',
    value: 2
  }],
  serviceItem: [],
  is_checkList: [{
    label: '全部',
    value: ''
  }, {
    label: '已检阅',
    value: 1
  }, {
    label: '未检阅',
    value: 2
  }],
  mobileOnlineStatus: [
    {
      label: '全部',
      value: ''
    }, {
      label: '上架',
      value: 1
    }, {
      label: '下架',
      value: 2
    }
  ],
  casesType: []
}
const mutations = {
  UPDATESERVICEITEM (state, value) {
    state.serviceItem = value
  },
  UPDATECASETYPE (state, value) {
    state.casesType = value
  }
}

const actions = {
  async getServiceItem ({ state, commit }) {
    if (state.serviceItem.length) {
      return
    }
    const data = await serviceItemApi()
    if (data.code === 1) {
      commit('UPDATESERVICEITEM', data.data)
    }
  },
  async getCasesType ({ state, commit }) {
    if (state.casesType.length) {
      return
    }
    const data = await casesTypeApi()
    if (data.code === 1) {
      commit('UPDATECASETYPE', data.data)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
