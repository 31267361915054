import _ from 'lodash'
import { treeToArr } from '@/utils/tools'
import store from '@/store'

/**
 * 过滤菜单
 * @param {Object} router 当前路由信息
 * @param {Array} menu 后端返回的菜单数组
 * @returns {number} 菜单数组的下标，不存在返回-1
 */
export function filterMenu (router, menu) {
  const newMenu = treeToArr(menu)
  return _.findIndex(newMenu, (o) => o.meta.id === router.meta.id)
}

/**
 * 判断当前操作是否有权限
 * @param {String} value 当前操作权限
 * @returns {boolean}
 */
export function includesIndentify (value) {
  const indentify = store.state.user.indentify
  return indentify.includes(value)
}
