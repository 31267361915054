<template>
  <div class="layout-container" :class="{'hide-aside': hideAside}">
    <el-main>
      <section class="app-main">
        <transition name="fade-transform" mode="out-in">
          <router-view/>
        </transition>
      </section>
    </el-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppMain',
  computed: {
    ...mapState('sys', ['hideAside'])
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/pc/variables.scss";
.app-main {
  /* 60= navbar  60  */
  min-height: calc(100vh - 60px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 0;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}

.layout-container {
  flex: 1;
  overflow-x: hidden;
  background: #f0f2f5;
  margin-left: $asideWidth;
  transition: margin-left 0.3s;

  &.hide-aside {
    margin-left: $asideHideWidth;
  }

  .el-main {
    padding: 0 20px;
  }

}
</style>
<style lang="scss">
// 全局样式
@import "~@/assets/styles/pc/app.scss";
</style>
