/**
 * @file global / ES module
 * @time 2021-11-28 10:28:19
 * @author PMun <https://github.com/SunPm>
 */

const state = {
  // 是否为移动端
  isMobile: false
}

const getters = {
}

const mutations = {
  updateMobileState (state, action) {
    state.isMobile = action
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
