import { uaParser } from '@/transformers/ua'

const { isMobile } = uaParser()

const state = {

}

const actions = {
  storeInit (store) {
    // 移动端
    if (isMobile) {
      // {root: true} 参数三：是配置选项, 申明这个 方法 不是当前模块的
      store.commit('global/updateMobileState', true, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  actions
}
