const state = {
  tabbarIndex: 0,
  tabbarList: [
    {
      to: '/sidebar',
      icon: 'wap-home-o',
      name: '菜单'
    },
    {
      to: '/consult/list',
      icon: 'orders-o',
      name: '咨询'
    },
    {
      to: '/cases/list',
      icon: 'hot-o',
      name: '案件'
    }
  ]
}

const mutations = {
  UPDATETABBAR (state, value) {
    state.tabbarIndex = value
  }
}

const actions = {
  setTabbarIndex ({ commit }, value) {
    commit('UPDATETABBAR', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
