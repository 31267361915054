import router from './index'
import store from '@/store'

let indentifyData = []
const whiteList = ['/login', '/auth-redirect'] // 无重定向白名单
router.beforeEach(async (to, from, next) => {
  // 获取缓存的用户信息
  const hasToken = JSON.parse(window.localStorage.getItem('userInfo')) || {}
  // 判断用户是否已登录
  if (hasToken.token) {
    if (to.path === '/login') {
      // 如果已登录，请重定向到主页
      next({ path: '/' })
    } else if (to.path === '/404') {
      // 404页面不管，直接进入
      next()
    } else if (to.path === '/') {
      // 手动重定向
      if (store.state.global.isMobile) {
        next('/sidebar')
      } else {
        next('/dashboard')
      }
    } else {
      // 获取用户权限信息
      const { indentify, newMenu } = await store.dispatch('user/getSysInfo')
      // 判断用户是否获取了router
      const hasRoles = store.state.permission.addRoutes && store.state.permission.addRoutes.length > 0
      if (hasRoles) {
        // console.log(indentify, indentifyData)
        if (JSON.stringify(indentify) !== JSON.stringify(indentifyData)) {
          // 判断权限标识，权限标识发生变化
          // 重置路由
          store.dispatch('permission/generateRoutes', { routes: newMenu, indentify }).then(res => {
            indentifyData = indentify
            router.addRoutes(res)
          })
        }
        next()
      } else {
        try {
          // 基于角色生成可访问线路图
          // 动态添加可访问路由
          indentifyData = indentify
          store.dispatch('permission/generateRoutes', { routes: newMenu, indentify }).then(res => {
            router.addRoutes(res)
            // 设置replace: true，这样导航就不会留下历史记录
            next({ ...to, replace: true })
            // next()
          })
        } catch (error) {
          // 删除token并转到登录页面以重新登录
          // await store.dispatch('user/resetToken')
          console.log(error)
          // window.localStorage.setItem('userInfo', null)
          // next(`/login?redirect=${to.path}`)
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 没有访问权限的其他页面将重定向到登录页面.
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {

})
