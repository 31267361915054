import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import initPlugins from './transformers/plugins'
// 权限过滤判断
import { includesIndentify } from './utils/permission'
// 路由拦截器
import './router/intercept'
// 过滤器
import '@/utils/filter'
import './assets/styles/index.scss'

// 初始化store
import './init'
import { dateFormat, dayFormat } from '@/utils/day'

// 初始化插件，各端差异引入不同的插件
initPlugins()

Vue.config.productionTip = false

Vue.prototype.$dayFormat = dayFormat
Vue.prototype.$dateFormat = dateFormat

// 自定义指令，判断有没有权限标识
Vue.directive('indentify', {
  bind: function (el, binding) {
    includesIndentify(binding.value) ? (el.style.display = '') : (el.style.display = 'none')
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
