<template>
  <el-header class="layout-header" :class="{'hide-aside': hideAside}">
    <div class="fold" @click="changeCollapse" v-if="hideAside"><i class="el-icon-s-unfold"></i></div>
    <div class="fold" @click="changeCollapse" v-else><i class="el-icon-s-fold"></i></div>
    <div class="flex-item breadcrumb-box">
      <!--<Breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>-->
    </div>
    <!--<screenfull id="screenfull"></screenfull>-->
    <div>
      <el-dropdown @command="handleCommand">
        <div>
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <span>{{ userInfo.nickname || '用户' }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="quit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { resetRouter } from '@/router'

export default {
  name: 'PcHeader',
  computed: {
    ...mapState('sys', ['hideAside'])
  },
  data () {
    return {
      userInfo: JSON.parse(window.localStorage.getItem('userInfo'))
    }
  },
  methods: {
    ...mapMutations('sys', ['UPDATEHIDEASIDE']),
    handleCommand (command) {
      if (command === 'quit') {
        window.localStorage.setItem('userInfo', null)
        resetRouter()
        this.$router.push('/login')
      }
    },
    changeCollapse () {
      this.UPDATEHIDEASIDE(!this.hideAside)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/pc/variables.scss";
.layout-header {
  @include transition(padding-left);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #515151;
  font-size: 16px;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  padding-left: $asideWidth;

  &.hide-aside {
    padding-left: $asideHideWidth;
  }

  .fold {
    font-size: 24px;
    cursor: pointer;
  }

  .flex-item {
    flex: 1;
  }
}
</style>
