<template>

  <el-container>
    <PcNavView :hideAside="hideAside"/>
    <PcHeaderView :hideAside="hideAside"/>
  </el-container>
</template>

<script>
import PcHeaderView from './header'
import PcNavView from './nav'
import { mapState } from 'vuex'

export default {
  name: 'PcMain',
  components: {
    PcHeaderView,
    PcNavView
  },
  computed: {
    ...mapState('sys', ['hideAside'])
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.box {
  font-size: 20px;
}
</style>
