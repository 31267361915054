import router, { resetRouter } from '@/router'
import { userSysInfoApi } from '@/api/out_put'
import { copyTrans } from '@/utils/tools'
import store from '@/store'
import { loginApi } from '@/api/login'

const state = {
  roles: ['admin'],
  token: '',
  permission: [],
  indentify: []
}

const mutations = {
  SET_PERMISSION: (state, permission) => {
    state.permission = permission
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INDENTIFY: (state, indentify) => {
    state.indentify = indentify
  }
}

const actions = {
  // 登录
  login ({ dispatch, commit }, userInfo) {
    // const { username, password } = userInfo
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const data = await loginApi(userInfo)
      if (data.code === 1) {
        window.localStorage.setItem('userInfo', JSON.stringify(data.data))
        commit('SET_TOKEN', data.data.token)
        resolve()
      }
    })
  },

  // 获取用户系统信息
  getSysInfo ({ commit, state }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const data = await userSysInfoApi()
      if (data.code === 1) {
        const { indentify, menu } = data.data
        const newMenu = copyTrans(menu, [{ key: 'url', value: 'path' }])
        commit('SET_PERMISSION', newMenu)
        resolve({ indentify, newMenu })
        commit('SET_INDENTIFY', indentify)
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('验证失败，请重新登录。')
      }
    })
  },

  // 重置token
  resetToken ({ commit, dispatch }) {
    return new Promise(resolve => {
      commit('SET_PERMISSION', [])
      dispatch('user/removeToken')
      // removeToken()
      resolve()
    })
  },

  // 清空token
  removeToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      resolve()
    })
  },

  // 动态修改权限
  async changeRoles ({ commit, dispatch }) {
    resetRouter()

    const { indentify, newMenu } = await store.dispatch('user/getSysInfo')
    const res = await store.dispatch('permission/generateRoutes', { routes: newMenu, indentify })
    router.addRoutes(res)

    // reset visited views and cached views
    // dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
