/**
 * @file index / ES module
 * @time 2021-11-28 10:49:57
 * @author PMun <https://github.com/SunPm>
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import gettersRouter from '@/router/getters'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    component: gettersRouter.Login
  },
  {
    path: '/',
    name: 'Layout',
    hidden: true,
    component: () => import('@/layout'),
    children: [
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: gettersRouter.About
      },
      {
        path: 'sidebar',
        name: 'Sidebar',
        component: gettersRouter.Sidebar
      },
      {
        path: 'setService',
        name: 'SetService',
        component: gettersRouter.SetService
      }
    ]
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

const router = createRouter()

export default router
