/**
 * @file day / ES module
 * @time 2021-11-21 16:45:35
 * @author PMun <https://github.com/SunPm>
 */
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { isMillisecond } from '@/utils/tools'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

dayjs.locale('zh-cn')

/**
 * 返回现在到当前实例的相对时间。
 * <br />https://day.js.org/docs/zh-CN/display/from-now#docsNav
 * @param date 时间戳
 * @return 1 秒钟前
 */
export function dateFromNow (date) {
  // 判断时间戳是否毫秒
  if (!isMillisecond(date)) {
    date = date * 1000
  }
  return dayjs(date).fromNow()
}

/**
 * 格式化时间
 * <br />https://day.js.org/docs/zh-CN/display/format#docsNav
 * @param date 时间戳
 * @param template 模板
 * @return YYYY年M月D日 HH:mm
 */
export function dateFormat (date, template = 'YYYY年MM月DD日 HH:mm:ss') {
  if (!isMillisecond(date)) {
    date = date * 1000
  }
  return dayjs(date).format(template)
}

// YYYY年MM月DD日
export function dayFormat (date) {
  return dateFormat(date, 'YYYY-MM-DD')
}

/**
 * 判断日期是否在另一个提供的日期时间之前
 * @param start 开始
 * @param end 结束
 * @return {boolean}
 */
export function timeComparison (start, end) {
  // 相同的时间返回true
  if (start === end) {
    return true
  } else {
    // 这表示 Day.js 对象是否在另一个提供的日期时间之前。
    // https://day.js.org/docs/zh-CN/query/is-before#docsNav
    return dayjs(start).isBefore(dayjs(end)) // 默认毫秒
  }
}
