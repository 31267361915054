import Vue from 'vue'
import store from '@/store'
import ElementUI from 'element-ui'
import '@/assets/styles/pc/element-variables.scss'

import 'vant/lib/index.css'

function initPlugins () {
  if (store.state.global.isMobile) {

  } else {
    Vue.use(ElementUI)
  }
}

export default initPlugins
