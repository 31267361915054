import Axios from 'axios'
import _ from 'lodash'
import { Message } from 'element-ui'
import qs from 'qs'
import router from '@/router'
import requestConfig from '@/config/request.config'

function http () {
  const url = '/back'
  const axios = Axios.create()
  const { token = '' } = JSON.parse(window.localStorage.getItem('userInfo')) || {}

  axios.interceptors.request.use(
    function (config) {
      config.headers = {
        token,
        platform: requestConfig.platform,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      config.url = url + config.url
      config.data = qs.stringify(config.data)
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    function (response) {
      const msg = _.get(response, 'data.info', '系统错误')
      if (response.data.code === 10001) {
        window.localStorage.setItem('userInfo', null)
        router.push('/login')
        return
      } else if (response.data.code === 10002) {
        Message.error('权限不足,请联系管理员!')
      } else if (response.data.code !== 1) {
        Message({
          message: msg || 'error',
          type: 'error',
          duration: 2 * 1000
        })
        throw new Error(msg)
      }
      return response.data
    },
    function (error) {
      return Promise.reject(error)
    }
  )
  return axios
}

export async function HttpGet (url) {
  return await http().get(url)
}

export async function HttpPost (url, jsonData) {
  return await http().post(url, jsonData)
}
