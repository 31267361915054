<template>
  <div></div>
</template>

<script>

export default {
  name: 'MobileMain',
  components: {
  }
}
</script>

<style scoped>

</style>
