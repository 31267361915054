const state = {
  payStatusEnum: {
    1: {
      type: 'info',
      label: '未支付'
    },
    2: {
      type: '',
      label: '已支付'
    }
  },
  orderTypeEnum: {
    1: {
      type: '',
      label: '用户'
    },
    2: {
      type: '',
      label: '后台'
    }
  },
  orderSourceEnum: {
    1: {
      type: '',
      label: '产品'
    },
    2: {
      type: '',
      label: '追问'
    }
  },
  callStatusEnum: {
    1: {
      type: 'info',
      label: '未拨打'
    },
    2: {
      type: '',
      label: '已拨打'
    }
  },
  is_checkEnum: {
    1: {
      type: '',
      label: '已检阅'
    },
    2: {
      type: 'info',
      label: '未检阅'
    }
  },
  smsPlanStatusEnum: {
    1: {
      type: 'info',
      label: '未发送'
    },
    2: {
      type: 'success',
      label: '已发送'
    },
    3: {
      type: 'error',
      label: '发送失败'
    },
    4: {
      type: 'warning',
      label: '取消发送'
    }
  },
  mobileOnlineStatusEnum: {
    2: {
      type: 'info',
      label: '下架'
    },
    1: {
      type: 'success',
      label: '上架'
    }
  }
}
const mutations = {

}

export default {
  namespaced: true,
  state,
  mutations
}
