import store from '@/store'
import _ from 'lodash'

/**
 * 计算百分比
 * @param   {number} num   分子
 * @param   {number} total 分母
 * @returns {number} 返回数百分比
 */
export function _percentage (num, total) {
  if (num === 0 || total === 0) {
    return 0
  }
  console.log(num)
  // return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
  return (Math.round(1 / 2 * 10000) / 100.00)// 小数点后两位百分比
}

/**
 * 对数组分页
 * @param {Number} pageNo 当前页数
 * @param {Number} pageSize 每页的数量
 * @param {Array} array 原数组
 * @returns {[]}
 * @private
 */
export function _pagination (pageNo, pageSize, array) {
  const offset = (pageNo - 1) * pageSize
  return (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize)
}

/**
 * &amp;nbsp空格转成正常空格
 * @param {Array} tableData
 * @param {String} key 数组对象的key
 */
export function _replaceNBSP (tableData, key) {
  if (tableData.length) {
    return tableData.map(item => {
      item[key] = item[key].replaceAll(/&nbsp;/g, '\u00a0\u00a0')
      return item
    })
  } else {
    return []
  }
}

/**
 * 把object转为Array
 * @example
 * ```js
 * {
 *   '1': '1223'
 * }
 * // 转为
 * [
 *  {
 *    label: '1223',
 *    value: '1'
 *  }
 * ]
 * ```
 * @param {Object} data
 * @returns {*[]}
 */
export function _object2Array (data) {
  let returnData = []
  Object.keys(data).forEach(key => {
    returnData = [...returnData, { label: data[key], value: key }]
  })
  return returnData
}

/**
 * 一维数组转树形结构数组
 * @param {Array} data 数组数据
 * @param {Number | String} id 唯一的Key
 * @param {Number | String} pidKey 父级的Key
 * @returns {*[]}
 * @private
 */
export function _toTree (data, { id = 'id', pidKey = 'pid' }) {
  const result = []
  if (!Array.isArray(data)) {
    return result
  }
  data.forEach(item => {
    delete item.children
  })
  const map = {}
  data.forEach(item => {
    map[item[id]] = item
  })
  data.forEach(item => {
    const parent = map[item[pidKey]]
    if (parent) {
      (parent.children || (parent.children = [])).push(item)
    } else {
      result.push(item)
    }
  })
  return result
}

/**
 * 树形结构数组转一维数组
 * @param {Array} arr 树形结构数组
 * @returns {*[]}
 */
export function treeToArr (arr) {
  const res = []
  function fn (childs) {
    childs.forEach(v => {
      const children = v.children
      // delete v.children
      res.push(v)
      // 循环一个个分离出来直到 children 为空为止
      if (children?.length) {
        fn(children)
      }
    })
  }
  fn(arr)
  return res
}

/**
 * 更改对象数组中的属性名
 * @param {Array} objArray 修改的对象数组
 * @param {Array} typeArr 要修改的key, 以对象数组传入
 * @example 将aaa对象中的type键改为chilren、model键改为chilren
 * console.log(copyTrans(aaa, [{ key: "type", value: "chilren" }, { key: "model", value: "chilren" }]));
 * @returns {{}|*}
 */
export function copyTrans (objArray, typeArr) {
  let result
  const toString = Object.prototype.toString
  if (toString.call(objArray) === '[object Array]') {
    result = []
    for (let i = 0; i < objArray.length; i++) {
      result[i] = copyTrans(objArray[i], arguments[1])
    }
  } else if (toString.call(objArray) === '[object Object]') {
    result = {}
    for (const _key in objArray) {
      // eslint-disable-next-line no-prototype-builtins
      if (objArray.hasOwnProperty(_key)) {
        let flag = 0; let _value = null
        for (let j = 0; j < arguments[1].length; j++) {
          if (arguments[1][j].key === _key) {
            flag = 1
            _value = arguments[1][j].value
          }
        }
        if (flag) { result[_value] = copyTrans(objArray[_key], arguments[1]) } else { result[_key] = copyTrans(objArray[_key], arguments[1]) }
      }
    }
  } else {
    return objArray
  }
  return result
}

/**
 * 设置状态颜色
 * @param status
 * @returns {string}
 */
export function setColor (status) {
  return status === 1 || status === 2 ? '#2264d7' : status === 3 ? '#ec2e2e' : status === 4 ? '#909399' : '#67C23A'
}

/**
 * 自定义地址选择框选中的数据
 * @param {String} province 省份
 * @param {String} city 城市
 * @param {String} county 县城
 */
export function setSelectedArea ({ province, city, county }) {
  // 树形结构的地址数据转换成一维
  const area = treeToArr(store.state.config.area)
  const selectedAreaName = [province, city, county]
  const selectedArea = []
  area.forEach(item => {
    // 通过name获取id，id填入使多列选择器联动
    if (selectedAreaName.indexOf(item.name) > -1) {
      selectedArea.push(item.id)
    }
  })
  // areaChange
  return selectedArea
}

/**
 * 数字相加并处理 0.1+0.2 ！= 0.3
 * @param {Array || String} arguments
 * @returns {number}
 * @constructor
 */
export function numAdd () {
  let sum = 0 // 用来保存总和
  // 解构，变成纯Array
  const arg = [...arguments]
  for (let i = 0; i < arg.length; i++) {
    const col = parseInt(arg[i])
    if (!isNaN(col)) {
      sum += col * 1000
    }
  }
  return sum / 1000
}

/**
 * 为树形数据添加字段
 * @param {Array} tree 树形数据
 * @param {Object} attr 数据
 * @param {String} attr.key 字段key
 * @param {any} attr.value 字段value
 * @param {Number} level 深度，哪一层添加字段
 * @return {*}
 */
export function setTreeAttr (tree, attr = { key: '', value: '' }, level = 3) {
  // 当前深度，也是当前层次
  const depth = 1
  // 深拷贝数组，避免改变原数据
  const copyTree = _.cloneDeep(tree)
  // 递归方法
  function fn (children, attr, level) {
    children && children.forEach(item => {
      // 判断层数
      if (depth === level) {
        item[attr.key] = attr.value
        // item.disabled = true
      } else {
        fn(item.children, attr, level - 1)
      }
    })
  }
  fn(copyTree, attr, level)
  return copyTree
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
export const isMillisecond = timeStamp => {
  const timeStr = String(timeStamp)
  return timeStr.length > 10
}
