import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import init from './modules/init'
import permission from './modules/permission'
import user from './modules/user'
import config from './modules/config'
import sys from './modules/sys'
import selectOptions from './modules/selectOptions'
import statusTagEnum from './modules/statusTagEnum'
import mConfig from './modules/m_config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    init,
    global,
    permission,
    config,
    user,
    statusTagEnum,
    selectOptions,
    sys,
    mConfig
  }
})
